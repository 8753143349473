/* eslint-disable new-cap */
'use strict';

var $isUserLoggedIn = $('#isUserLoggedIn').val();
var loginURL = $('#loginURL').val();

if ($isUserLoggedIn === 'false') {
    if ($('.new-register-popout').length > 0) {
        var container = $('.new-register-popout-wrapper');
        var $xcluded = $('.home-main-banner .swiper-slide .video-content a, .experiences-box a, .add-to-cart-area .js-trigger-update-cart, .add-to-cart-area .js-trigger-add-to-cart, #page-main-footer ul li a, .js-trigger-resgister-popout, .idealz-credit-campaign-box .campaign-new-image a, .js-open-menu, .closing-add-to-cart, .js-trigger-cart, .js-initial-add-to-cart , .newCart-indicator, .featured-img .campaign-new-image a, .js-add-bundle-to-cart, .show-login');

        $xcluded.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = loginURL;
            // $('.new-register-popout').addClass('show');
        });

        $(document).on('livecampaign:loaded', function () {
            $('.home-main-banner .swiper-slide .video-content a, .experiences-box a, .add-to-cart-area .js-trigger-update-cart, .add-to-cart-area .js-trigger-add-to-cart, #page-main-footer ul li a, .js-trigger-resgister-popout, .idealz-credit-campaign-box .campaign-new-image a, .js-open-menu, .closing-add-to-cart, .js-trigger-cart, .js-initial-add-to-cart, .newCart-indicator, .featured-img .campaign-new-image a, .js-add-bundle-to-cart, .show-login').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                window.location.href = loginURL;
                // $('.new-register-popout').addClass('show');
            });
        });

        $('.new-register-popout').on('click', function (e) {
            if (container.parent().hasClass('show') && !container.is(e.target) && container.has(e.target).length === 0) {
                $('.new-register-popout').removeClass('show');
            }
        });
    }
}

$(document).on('click', '.login-page .account-tabs a', function(e) {
    e.preventDefault();
    var $this = $(this);
    var $data = $this.data('tab');

    $this.addClass('active').parent().siblings().find('a').removeClass('active');
    if (!$('.login-page').length > 0) {
        $('.account-button-tab').toggleClass('active');
    } else {
        if ($data === 'login') {
            $('.register-box').fadeOut(100, function () {
                $('.login-box').fadeIn();
                $('.login-profile-selection').removeClass('is-register');
            });
        } else {
            $('.login-box').fadeOut(100, function () {
                $('.register-box').fadeIn();
                $('.login-profile-selection').addClass('is-register');
            });
        }
    }
});

